import React from "react"
import { Link } from "gatsby"

import { publisherName } from "../config/config"

export const Footer = () => (
  <footer className="ampstart-footer flex flex-column items-center">
    <nav className="ampstart-footer-nav">
      <ul className="list-reset flex flex-wrap mb3 ml0">
        <li className="px1">
          <Link to="/">トップ</Link>
        </li>
        <li className="px1">
          <Link to="/contact/">お問い合わせ</Link>
        </li>
        <li className="px1">
          <Link to="/terms_of_use/">利用規約</Link>
        </li>
      </ul>
    </nav>
    <span>© {publisherName}</span>
  </footer>
)
