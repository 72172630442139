import React from "react"

export default function GooglFont() {
  return (
    <link
      href="//fonts.googleapis.com/css?family=Noto+Sans+JP:400&display=swap"
      rel="stylesheet"
      type="text/css"
    />
  )
}
