import React from "react"

export const Navbar = () => (
  <header className="ampstart-headerbar fixed flex justify-center items-center top-0 left-0 right-0 pl2 pr4 ">
    <a href="/">
      <img
        src="/logo.png"
        width="300"
        height="30"
        layout="fixed"
        className="my1 align-bottom"
        alt="logo"
      ></img>
    </a>
  </header>
)
